<template>
	<div class="waterfalls">
		<div class="row-me">
			<div class="item-fall flex1" v-for="(item,index) in dataArray" :key="index"
				:style="{'margin-right':index!==dataArray.length-1?space+'px':'0px'}">
				<div class="column-me center-all">
					<div class="item-div column-me row-center" v-for="(item1,index1) in item" :key="'two'+index1"
						:style="{'margin-bottom':space+'px'}">
						<slot name="fallItem" :item="item1"></slot>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'waterfalls',
		props: {
			columns: { //列数
				value: Number,
				default: 2
			},
			data: { //渲染的数据
				value: Array,
				default: []
			},
			space: { //间隔
				value: Number,
				default: 15
			},
		},
		data() {
			return {
				dataArray: [], //渲染的列数集合，结构三列：[[{},{}],[{},{],[{},{]]
			}
		},
		mounted() {
			this.initData()
		},
		methods: {
			initData() {
				this.dataArray = []
				let num = this.columns
				for (var i = 0; i < this.data.length; i++) {
					let item = this.data[i]
					let n = i % num
					if (!this.dataArray[n]) { //之前没有，先创建一个数组
						this.dataArray.splice(n, 0, [])
					}
					this.dataArray[n].push(item)
				}
				// console.log('瀑布流数据:', this.dataArray)
			}
		},
		computed: {

		},
		watch: {
			'data': {
				handler: function(newValue, oldValue) {
					this.initData()
				},
				deep: true,
			}
		},
	}
</script>

<style lang="scss" scoped>
	.waterfalls {
		width: 100%;
		height: fit-content;

		.row-me {
			display: flex;
			flex-direction: row;
		}

		.row-center {
			align-items: center;
		}

		.column-me {
			display: flex;
			flex-direction: column;
		}

		.column-center {
			justify-content: center;
		}

		.center-all {
			justify-content: center;
			align-items: center;
		}

		.flex1 {
			flex: 1;
		}

		.flex2 {
			flex: 2;
		}

		.item-fall {
			&:nth-last-child {
				margin-right: 0 !important;
			}
		}

		.item-div {
			width: 100%;
		}
	}
</style>
