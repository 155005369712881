<template>
  <div class="about-us-mobile" v-if="isMobile">
    <img src="../assets/img/banner_home.png" class="img-banner">
    <div class="main-content">
      <div class="row-me tab-div">
        <div class="column-me center-all left-div">
          <div class="title1">品牌介绍</div>
          <div class="title2">ABOUT US</div>
        </div>
        <div class="row-me row-center flex-wrap flex1">
          <div class="title0" :class="{'title0-active':tabIndex===0}" @click="clickIndex(0)">品牌介绍</div>
          <div class="tab-line"></div>
          <div class="title0" :class="{'title0-active':tabIndex===4}" @click="clickIndex(4)">发展历程</div>
          <div class="tab-line"></div>
          <div class="title0" :class="{'title0-active':tabIndex===1}" @click="clickIndex(1)">资质荣誉</div>
          <div class="tab-line"></div>
          <div class="title0" :class="{'title0-active':tabIndex===2}" @click="clickIndex(2)">门店展示</div>
          <div class="tab-line"></div>
          <div class="title0" :class="{'title0-active':tabIndex===3}" @click="clickIndex(3)">新闻动态</div>
        </div>
      </div>
      <!-- 品牌介绍 -->
      <div class="column-me row-center" v-if="tabIndex===0">
        <div class="row-me center-all margin-top50">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left10 margin-right10">
            <div class="title">品牌发展</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="subtitle">BRAND DEVELOPMENT</div>
        <div class="column-me row-center width100Per margin-top30">
          <div class="black-div anim-bigger-small">
            <div class="title-black">
              坐落于中国光谷（青岛研创中心），是红尾狐品牌旗下以少儿体适能培训和少儿运动竞技为主体的创业孵化平台，
              下设“红小狐体适能运动中心”、“红尾狐卡丁车训练中心”、“红尾狐主题乐园”、“红尾狐数字运动公园”、“红尾狐研学基地”、
              “红尾狐商学院”和“小狐出行”七大项目。红尾狐作为国家体育总局旗下中体教培会员单位，具有科学的课研教学体系、
              雄厚的技术研发实力、完备的供应链体系及完善的运营管理赋能体系，并与南京体育大学、韩国庆北国立大学、
              韩国职业棒球联盟（KBO）、中国石油大学、青岛理工大学等多家高校机构建立合作，聘请南京体育学院教授、研究生导师、
              南京体育学院王正伦院长等多位教育专家和世界冠军为课研顾问团队，为我们的合作伙伴的发展保驾护航。
              <br><br>红尾狐将成为合作伙伴成就事业的忠实支持者和可靠伴随者!
            </div>
          </div>
          <img src="../assets/img/img1.png" class="img-develop">
        </div>
        <div class="row-me center-all margin-top60">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left10 margin-right10">
            <div class="title">品牌文化</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="subtitle">BRAND CULTURE</div>
        <div class="column-me row-center width100Per flex-sb margin-top50">
          <div class="card-div posRelative">
            <div @mouseenter="cultureIndex=0">
              <img src="../assets/img/img2.png" class="width100Per height100Per objFitCover">
              <div class="column-me center-all yellow-mask posAbsoluteAll">
                <div class="title1">使命</div>
                <div class="title2">MISSION</div>
              </div>
            </div>
            <transition name="el-zoom-in-bottom">
              <div class="column-me show-div posAbsoluteAll" v-if="cultureIndex===0"
                   @mouseleave="cultureIndex=-1">
                <img src="../assets/img/img2.png" class="img2">
                <div class="black1 column-me flex1">
                  <div class="title11">使命</div>
                  <div class="title22">
                    运动让每一位孩子快乐成长！<br>
                    体教融合、坚韧独立、品质高贵、兴趣引导
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <div class="card-div posRelative margin-top20">
            <div @mouseenter="cultureIndex=1">
              <img src="../assets/img/img3.png" class="width100Per height100Per objFitCover">
              <div class="column-me center-all yellow-mask posAbsoluteAll">
                <div class="title1">愿景</div>
                <div class="title2">VISION</div>
              </div>
            </div>
            <transition name="el-zoom-in-bottom">
              <div class="column-me show-div posAbsoluteAll" v-if="cultureIndex===1"
                   @mouseleave="cultureIndex=-1">
                <img src="../assets/img/img3.png" class="img2">
                <div class="black1 column-me flex1">
                  <div class="title11">愿景</div>
                  <div class="title22">
                    打造少儿运动竞技，体适能素质教育创业孵化专业平台<br>
                    引领行业、育人为本、国富民强、使命必达
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div class="column-me row-center width100Per flex-sb margin-top20">
          <div class="card-div posRelative">
            <div @mouseenter="cultureIndex=2">
              <img src="../assets/img/img4.png" class="width100Per height100Per objFitCover">
              <div class="column-me center-all yellow-mask posAbsoluteAll">
                <div class="title1">价值观</div>
                <div class="title2">SENSE OF WORTH</div>
              </div>
            </div>
            <transition name="el-zoom-in-bottom">
              <div class="column-me show-div posAbsoluteAll" v-if="cultureIndex===2"
                   @mouseleave="cultureIndex=-1">
                <img src="../assets/img/img4.png" class="img2">
                <div class="black1 column-me flex1">
                  <div class="title11">价值观</div>
                  <div class="title22">
                    共建共创，共担共享<br>
                    思己利人、诚信进取、精进共赢、共同发展
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <div class="card-div posRelative margin-top20">
            <div @mouseenter="cultureIndex=3">
              <img src="../assets/img/img5.png" class="width100Per height100Per objFitCover">
              <div class="column-me center-all yellow-mask posAbsoluteAll">
                <div class="title1">教学理念</div>
                <div class="title2">TEACHING CONCEPT</div>
              </div>
            </div>
            <transition name="el-zoom-in-bottom">
              <div class="column-me show-div posAbsoluteAll" v-if="cultureIndex===3"
                   @mouseleave="cultureIndex=-1">
                <img src="../assets/img/img5.png" class="img2">
                <div class="black1 column-me flex1">
                  <div class="title11">教学理念</div>
                  <div class="title22">
                    科学实用，专业专注，真诚务实：一切教育都是从对儿童天性理解开始，愿每个孩子都成为一个善良，丰富个，高贵的人。
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>

      </div>
      <!-- 发展历程 -->
      <div class="column-me row-center" v-if="tabIndex===4">
        <div class="row-me center-all margin-top50">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left10 margin-right10">
            <div class="title">发展历程</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="subtitle">DEVELOPMENT HISTORY</div>
        <div class="column-me margin-top30 width100Per">
          <div>
            <div class="column-me" v-for="(item,index) in 10" :key="index">
              <div class="round-date-div column-me center-all" :style="{'border-color':index%2!==0?'#FCCB00':''}">
                <div class="date">2021</div>
                <div class="year">year</div>
              </div>
              <div class="right-content">
                <div class="row-me padding-top20 padding-bottom20" v-for="(item1,index1) in 3" :key="index1">
                  <div class="row-me row-center btn-height40">
                    <div class="black-point" :style="{'background':index%2!==0?'#FCCB00':''}"></div>
                    <div class="month">4月</div>
                    <div class="grey-point"></div>
                  </div>
                  <div class="content flex1">
                    全新儿童卡丁车红狐一号量产卡丁车供应链工厂建成
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      <!-- 资质荣誉 -->
      <div class="column-me row-center" v-if="tabIndex===1">
        <div class="row-me center-all margin-top60">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left10 margin-right10">
            <div class="title">资质荣誉</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="subtitle">QUALIFICATION HONOR</div>
        <div class="row-me margin-top80">
          <waterfalls :columns="1" :data="dataArray" :space="0">
            <template v-slot:fallItem="scope">
              <div class="column-me item-card" @click="clickHonor(scope.item)">
                <el-image :src="scope.item.logo" fit="cover" class="img-card">
                  <template #error>
                    <img src="../assets/img/default.png" slot="error"
                         class="width100Per height100Per objFitCover">
                  </template>
                </el-image>
                <div class="title1 line1" v-if="scope.item.name">{{ scope.item.name }}</div>
                <div class="title2" v-if="scope.item.create_time">{{ scope.item.create_time }}</div>
                <div class="title3 line2" v-html="scope.item.content" v-if="scope.item.content"></div>
              </div>
            </template>
          </waterfalls>
        </div>
      </div>
      <!-- 门店展示 -->
      <div class="column-me row-center" v-if="tabIndex===2">
        <div class="row-me center-all margin-top60">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left10 margin-right10">
            <div class="title">门店展示</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="subtitle">SHOP DISPLAY</div>
        <div class="column-me row-center margin-top50 flex-wrap">
          <div class="item-show row-me row-center" v-for="(item,index) in storeArray" :key="index"
               @click="clickStore(item)">
            <el-image :src="item.img" fit="cover" class="item-img img-round">
              <template #error>
                <img src="../assets/img/default.png" slot="error"
                     class="width100Per height100Per objFitCover">
              </template>
            </el-image>
            <div class="column-me margin-left30 flex1">
              <div class="title1">{{ item.name }}</div>
              <div class="title2">{{ item.address }}</div>
            </div>
            <div class="height100Per">
              <div class="look-div">查看</div>
            </div>
          </div>
        </div>
        <div class="row-me center-all margin-top50">
          <el-pagination background layout="prev, pager, next" :total="storeTotal" :page-size="limitStore"
                         @current-change="storePageChange">
          </el-pagination>
        </div>
        <div class="row-me center-all margin-top100">
          <div class="column-me row-center margin-left60 margin-right60">
            <div class="title">门店视频</div>
            <div class="subtitle">SHOP VIDEO</div>
          </div>
        </div>
        <div class="column-me center-all margin-top50 flex-wrap width100Per">
          <div class="item-video" v-for="(item,index) in videoArray" :key="index">
            <video :src="item.video" class="video" controls :poster="item.img"></video>
          </div>
        </div>
        <img src="../assets/img/more2.png" class="img-more anim-bigger-small"
             v-if="videoArray.length<videoTotal" @click="clickMoreVideo">
        <div class="row-me center-all margin-top100">
          <div class="column-me row-center margin-left60 margin-right60">
            <div class="title">门店图册</div>
            <div class="subtitle">SHOP IMAGE</div>
          </div>
        </div>
        <div class="margin-top50">
          <waterfalls :columns="2" :data="imageArray" :space="30">
            <template v-slot:fallItem="scope">
              <el-image :src="scope.item.img" :preview-src-list="[scope.item.img]" class="img-shop"
                        fit="fill" hide-on-click-modal>
                <template #error>
                  <img src="../assets/img/default.png" slot="error"
                       class="width100Per height100Per objFitCover">
                </template>
              </el-image>
            </template>
          </waterfalls>
        </div>
        <img src="../assets/img/more2.png" class="img-more anim-bigger-small margin-bottom50"
             v-if="imageArray.length<imageTotal" @click="clickMoreImage">
      </div>
      <!-- 新闻动态 -->
      <div class="column-me row-center" v-if="tabIndex===3">
        <div class="row-me center-all margin-top90">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left10 margin-right10">
            <div class="title">新闻动态</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="subtitle">NEWS</div>
        <div class="margin-top50 width100Per">
          <div class="item-news row-me row-center" v-for="(item,index) in newsArray" :key="index"
               @click="clickNews(item)">
            <el-image :src="item.cover" class="img-news" fit="cover">
              <template #error>
                <img src="../assets/img/default.png" slot="error"
                     class="width100Per height100Per objFitCover">
              </template>
            </el-image>
            <div class="column-me height100Per flex1 margin-left10 margin-right10">
              <div class="news-title line1">
                {{ item.title }}
              </div>
              <div class="news-date">{{ item.create_time }}</div>
              <div class="news-content line3 margin-top10" v-html="item.content"></div>
            </div>
          </div>
        </div>
        <div class="row-me center-all margin-top50 margin-bottom30">
          <el-pagination background layout="prev, pager, next" :total="newsTotal" :page-size="limitNews"
                         @current-change="newsPageChange">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 门店地址弹窗 -->
    <el-dialog :title="selectStore.name" v-model="dialogStoreVisible" width="90%">
      <div class="map-div" id="container"></div>
    </el-dialog>
  </div>


  <div class="about-us" v-else>
    <img src="../assets/img/banner_home.png" class="img-banner">
    <div class="main-content">
      <div class="row-me row-center tab-div">
        <div class="row-me center-all left-div">
          <div class="title1">品牌介绍</div>
          <div class="title2 margin-left20">ABOUT US</div>
        </div>
        <div class="row-me row-center flex-end flex1 padding-right30">
          <div class="title0" :class="{'title0-active':tabIndex===0}" @click="clickIndex(0)">品牌介绍</div>
          <div class="tab-line"></div>
          <div class="title0" :class="{'title0-active':tabIndex===4}" @click="clickIndex(4)">发展历程</div>
          <div class="tab-line"></div>
          <div class="title0" :class="{'title0-active':tabIndex===1}" @click="clickIndex(1)">资质荣誉</div>
          <div class="tab-line"></div>
          <div class="title0" :class="{'title0-active':tabIndex===2}" @click="clickIndex(2)">门店展示</div>
          <div class="tab-line"></div>
          <div class="title0" :class="{'title0-active':tabIndex===3}" @click="clickIndex(3)">新闻动态</div>
        </div>
      </div>
      <!-- 品牌介绍 -->
      <div class="column-me row-center" v-if="tabIndex===0">
        <div class="row-me center-all margin-top90">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left60 margin-right60">
            <div class="title">品牌发展</div>
            <div class="subtitle">BRAND DEVELOPMENT</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="row-me row-center width100Per margin-top80">
          <div class="black-div anim-bigger-small">
            <div class="title-black">
              坐落于中国光谷（青岛研创中心），是红尾狐品牌旗下以少儿体适能培训和少儿运动竞技为主体的创业孵化平台，
              下设“红小狐体适能运动中心”、“红尾狐卡丁车训练中心”、“红尾狐主题乐园”、“红尾狐数字运动公园”、“红尾狐研学基地”、
              “红尾狐商学院”和“小狐出行”七大项目。红尾狐作为国家体育总局旗下中体教培会员单位，具有科学的课研教学体系、
              雄厚的技术研发实力、完备的供应链体系及完善的运营管理赋能体系，并与南京体育大学、韩国庆北国立大学、
              韩国职业棒球联盟（KBO）、中国石油大学、青岛理工大学等多家高校机构建立合作，聘请南京体育学院教授、研究生导师、
              南京体育学院王正伦院长等多位教育专家和世界冠军为课研顾问团队，为我们的合作伙伴的发展保驾护航。
              <br>红尾狐将成为合作伙伴成就事业的忠实支持者和可靠伴随者!
            </div>
          </div>
          <img src="../assets/img/img1.png" class="img-develop">
        </div>
        <div class="row-me center-all margin-top130">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left60 margin-right60">
            <div class="title">品牌文化</div>
            <div class="subtitle">BRAND CULTURE</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="row-me row-center flex-sb margin-top80">
          <div class="card-div posRelative">
            <div @mouseenter="cultureIndex=0">
              <img src="../assets/img/img2.png" class="width100Per height100Per objFitCover">
              <div class="column-me center-all yellow-mask posAbsoluteAll">
                <div class="title1">使命</div>
                <div class="title2">MISSION</div>
              </div>
            </div>
            <transition name="el-zoom-in-bottom">
              <div class="column-me show-div posAbsoluteAll" v-if="cultureIndex===0"
                   @mouseleave="cultureIndex=-1">
                <img src="../assets/img/img2.png" class="img2">
                <div class="black1 column-me flex1">
                  <div class="title11">使命</div>
                  <div class="title22">
                    运动让每一位孩子快乐成长！<br>
                    体教融合、坚韧独立、品质高贵、兴趣引导
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <div class="card-div posRelative margin-left40">
            <div @mouseenter="cultureIndex=1">
              <img src="../assets/img/img3.png" class="width100Per height100Per objFitCover">
              <div class="column-me center-all yellow-mask posAbsoluteAll">
                <div class="title1">愿景</div>
                <div class="title2">VISION</div>
              </div>
            </div>
            <transition name="el-zoom-in-bottom">
              <div class="column-me show-div posAbsoluteAll" v-if="cultureIndex===1"
                   @mouseleave="cultureIndex=-1">
                <img src="../assets/img/img3.png" class="img2">
                <div class="black1 column-me flex1">
                  <div class="title11">愿景</div>
                  <div class="title22">
                    打造少儿运动竞技，体适能素质教育创业孵化专业平台<br>
                    引领行业、育人为本、国富民强、使命必达
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div class="row-me row-center flex-sb margin-top40">
          <div class="card-div posRelative">
            <div @mouseenter="cultureIndex=2">
              <img src="../assets/img/img4.png" class="width100Per height100Per objFitCover">
              <div class="column-me center-all yellow-mask posAbsoluteAll">
                <div class="title1">价值观</div>
                <div class="title2">SENSE OF WORTH</div>
              </div>
            </div>
            <transition name="el-zoom-in-bottom">
              <div class="column-me show-div posAbsoluteAll" v-if="cultureIndex===2"
                   @mouseleave="cultureIndex=-1">
                <img src="../assets/img/img4.png" class="img2">
                <div class="black1 column-me flex1">
                  <div class="title11">价值观</div>
                  <div class="title22">
                    共建共创，共担共享<br>
                    思己利人、诚信进取、精进共赢、共同发展
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <div class="card-div posRelative margin-left40">
            <div @mouseenter="cultureIndex=3">
              <img src="../assets/img/img5.png" class="width100Per height100Per objFitCover">
              <div class="column-me center-all yellow-mask posAbsoluteAll">
                <div class="title1">教学理念</div>
                <div class="title2">TEACHING CONCEPT</div>
              </div>
            </div>
            <transition name="el-zoom-in-bottom">
              <div class="column-me show-div posAbsoluteAll" v-if="cultureIndex===3"
                   @mouseleave="cultureIndex=-1">
                <img src="../assets/img/img5.png" class="img2">
                <div class="black1 column-me flex1">
                  <div class="title11">教学理念</div>
                  <div class="title22">
                    科学实用，专业专注，真诚务实：一切教育都是从对儿童天性理解开始，愿每个孩子都成为一个善良，丰富个，高贵的人。
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <!-- 发展历程 -->
      <div class="column-me row-center" v-if="tabIndex===4">
        <div class="row-me center-all margin-top90">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left60 margin-right60">
            <div class="title">发展历程</div>
            <div class="subtitle">DEVELOPMENT HISTORY</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="column-me margin-top80 width100Per">
          <div class="margin-left180">
            <div class="column-me" v-for="(item,index) in 10" :key="index">
              <div class="round-date-div column-me center-all" :style="{'border-color':index%2!==0?'#FCCB00':''}">
                <div class="date">2021</div>
                <div class="year">year</div>
              </div>
              <div class="right-content">
                <div class="row-me padding-top20 padding-bottom20" v-for="(item1,index1) in 3" :key="index1">
                  <div class="row-me row-center btn-height40">
                    <div class="black-point" :style="{'background':index%2!==0?'#FCCB00':''}"></div>
                    <div class="month">4月</div>
                    <div class="grey-point"></div>
                  </div>
                  <div class="content flex1">
                    全新儿童卡丁车红狐一号量产卡丁车供应链工厂建成
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      <!-- 资质荣誉 -->
      <div class="column-me row-center" v-if="tabIndex===1">
        <div class="row-me center-all margin-top90">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left60 margin-right60">
            <div class="title">资质荣誉</div>
            <div class="subtitle">QUALIFICATION HONOR</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="row-me margin-top80">
          <waterfalls :columns="3" :data="dataArray" :space="30">
            <template v-slot:fallItem="scope">
              <div class="column-me item-card" @click="clickHonor(scope.item)">
                <el-image :src="scope.item.logo" fit="cover" class="img-card">
                  <template #error>
                    <img src="../assets/img/default.png" slot="error"
                         class="width100Per height100Per objFitCover">
                  </template>
                </el-image>
                <div class="title1 line1" v-if="scope.item.name">{{ scope.item.name }}</div>
                <div class="title2" v-if="scope.item.create_time">{{ scope.item.create_time }}</div>
                <div class="title3 line2" v-html="scope.item.content" v-if="scope.item.content"></div>
              </div>
            </template>
          </waterfalls>
        </div>
      </div>
      <!-- 门店展示 -->
      <div class="column-me row-center" v-if="tabIndex===2">
        <div class="row-me center-all margin-top90">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left60 margin-right60">
            <div class="title">门店展示</div>
            <div class="subtitle">SHOP DISPLAY</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="row-me row-center margin-top80 flex-wrap">
          <div class="item-show row-me row-center" v-for="(item,index) in storeArray" :key="index"
               @click="clickStore(item)">
            <el-image :src="item.img" fit="cover" class="width100 height100 img-round margin-left30">
              <template #error>
                <img src="../assets/img/default.png" slot="error"
                     class="width100Per height100Per objFitCover">
              </template>
            </el-image>
            <div class="column-me margin-left30 flex1">
              <div class="title1">{{ item.name }}</div>
              <div class="title2">{{ item.address }}</div>
            </div>
            <div class="height100Per">
              <div class="look-div">查看</div>
            </div>
          </div>
        </div>
        <div class="row-me center-all margin-top80">
          <el-pagination background layout="prev, pager, next" :total="storeTotal" :page-size="limitStore"
                         @current-change="storePageChange">
          </el-pagination>
        </div>
        <div class="row-me center-all margin-top100">
          <div class="column-me row-center margin-left60 margin-right60">
            <div class="title">门店视频</div>
            <div class="subtitle">SHOP VIDEO</div>
          </div>
        </div>
        <div class="row-me center-all margin-top80 flex-wrap">
          <div class="item-video" v-for="(item,index) in videoArray" :key="index">
            <video :src="item.video" class="video" controls :poster="item.img"></video>
          </div>
        </div>
        <img src="../assets/img/more2.png" class="img-more anim-bigger-small"
             v-if="videoArray.length<videoTotal" @click="clickMoreVideo">
        <div class="row-me center-all margin-top100">
          <div class="column-me row-center margin-left60 margin-right60">
            <div class="title">门店图册</div>
            <div class="subtitle">SHOP IMAGE</div>
          </div>
        </div>
        <div class="margin-top70">
          <waterfalls :columns="4" :data="imageArray" :space="30">
            <template v-slot:fallItem="scope">
              <el-image :src="scope.item.img" :preview-src-list="[scope.item.img]" class="img-shop"
                        fit="fill" hide-on-click-modal>
                <template #error>
                  <img src="../assets/img/default.png" slot="error"
                       class="width100Per height100Per objFitCover">
                </template>
              </el-image>
            </template>
          </waterfalls>
        </div>
        <img src="../assets/img/more2.png" class="img-more anim-bigger-small margin-bottom50"
             v-if="imageArray.length<imageTotal" @click="clickMoreImage">
      </div>
      <!-- 新闻动态 -->
      <div class="column-me row-center" v-if="tabIndex===3">
        <div class="row-me center-all margin-top90">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left60 margin-right60">
            <div class="title">新闻动态</div>
            <div class="subtitle">NEWS</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="margin-top50 width100Per">
          <div class="item-news row-me row-center" v-for="(item,index) in newsArray" :key="index"
               @click="clickNews(item)">
            <el-image :src="item.cover" class="img-news" fit="cover">
              <template #error>
                <img src="../assets/img/default.png" slot="error"
                     class="width100Per height100Per objFitCover">
              </template>
            </el-image>
            <div class="column-me height100Per flex1 margin-left50 margin-right50">
              <div class="row-me row-center flex-sb margin-top40">
                <div class="news-title flex1 margin-right50 line1">
                  {{ item.title }}
                </div>
                <div class="news-date">{{ item.create_time }}</div>
              </div>
              <div class="news-content line3 margin-top30" v-html="item.content"></div>
            </div>
          </div>
        </div>
        <div class="row-me center-all margin-top80">
          <el-pagination background layout="prev, pager, next" :total="newsTotal" :page-size="limitNews"
                         @current-change="newsPageChange">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 门店地址弹窗 -->
    <el-dialog :title="selectStore.name" v-model="dialogStoreVisible">
      <div class="map-div" id="container"></div>
    </el-dialog>
  </div>
</template>

<script>
import waterfalls from '../components/waterfalls.vue'
import AMap from 'AMap' // 引入高德地图
export default {
  components: {
    waterfalls
  },
  data() {
    return {
      tabIndex: 0,
      dataArray: [], //资质荣誉
      cultureIndex: -1,
      storeArray: [], //门店数组
      storeTotal: 0, //总条数
      imageArray: [], //门店相册
      imageTotal: 0, //总条数
      videoArray: [], //门店视频
      videoTotal: 0, //总条数
      pageStore: 1,
      pageImage: 1,
      pageVideo: 1,
      limitStore: 12,
      limitImage: 8,
      limitVideo: 4,
      dialogStoreVisible: false,
      selectStore: {}, //点击的门店
      newsArray: [], //新闻数组
      newsTotal: 0, //总条数
      pageNews: 1,
      limitNews: 5,
      isMobile: false,
    }
  },
  // html加载完成之前
  created() {

  },
  // html加载完成后执行。
  mounted() {
    this.isMobile = sessionStorage.getItem('mobile') === '1'
  },
  // 事件方法执行
  methods: {
    clickIndex(index) {
      this.$router.push({
        path: '/about_us',
        query: {
          index: index
        }
      })
    },
    //新闻点击
    clickNews(item) {
      this.$router.push({
        path: '/detail',
        query: {
          id: item.id
        }
      })
    },
    //资质荣誉点击
    clickHonor(item) {
      this.$router.push({
        path: '/detail_honor',
        query: {
          id: item.id
        }
      })
    },
    //门店点击
    clickStore(item) {
      let that = this
      this.selectStore = item
      this.dialogStoreVisible = true
      this.$nextTick(() => {
        if (this.isIos()) {
          this.initStoreMapIos(item)
        } else {
          this.initStoreMap(item)
        }
      })
    },
    //更多视频
    clickMoreVideo() {
      this.pageVideo++
      this.getVideoList()
    },
    //更多图片
    clickMoreImage() {
      this.pageImage++
      this.getPhotos()
    },
    //门店页码改变
    storePageChange(val) {
      this.pageStore = val
      this.getShops()
      document.getElementById('content').scrollTop = 600
    },
    //新闻页码改变
    newsPageChange(val) {
      this.pageNews = val
      this.getNews()
      document.getElementById('content').scrollTop = 600
    },
    //获取资质荣誉
    getHonor() {
      this.$postRequest('/api/index/getHonor', {}).then((res) => {
        if (res) {
          this.dataArray = res.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    //获取店铺列表
    getShops() {
      this.$postRequest('/api/index/getShops', {
        page: this.pageStore,
        limit: this.limitStore
      }).then((res) => {
        if (res) {
          this.storeTotal = res.data.total
          this.storeArray = res.data.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    //获取店铺视频
    getVideoList() {
      this.$postRequest('/api/index/getVideoList', {
        page: this.pageVideo,
        limit: this.limitVideo
      }).then((res) => {
        if (res) {
          this.videoTotal = res.data.total
          if (this.pageVideo === 1) {
            this.videoArray = res.data.data
          } else {
            this.videoArray = this.videoArray.concat(res.data.data)
          }
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    //获取店铺相册
    getPhotos() {
      this.$postRequest('/api/index/getPhotos', {
        page: this.pageImage,
        limit: this.limitImage
      }).then((res) => {
        if (res) {
          this.imageTotal = res.data.total
          if (this.pageImage === 1) {
            this.imageArray = res.data.data
          } else {
            this.imageArray = this.imageArray.concat(res.data.data)
          }
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    //初始化门店地图
    initStoreMap(item) {
      var map = new AMap.Map('container', {
        resizeEnable: true,
        center: [Number(item.longitude), Number(item.latitude)],
        zoom: 17,
        viewMode: '3D' //使用3D视图
      });
      var marker = new AMap.Marker({
        position: [Number(item.longitude), Number(item.latitude)], //位置
        title: item.name,
        icon: require('../assets/img/position.png'),
        offset: new AMap.Pixel(-30, -40)
      })
      map.add(marker); //添加到地图
      let content =
          '<div class="map-info-window-content row-me center-all">' +
          '<div class="map-company-name">' + item.name + '</div>' +
          '</div>';
      if (this.isMobile) {
        var infoWindow = new AMap.InfoWindow({
          isCustom: true, //使用自定义窗体
          showShadow: true,
          content: content,
          offset: new AMap.Pixel(-10, 120)
        });
        infoWindow.open(map, marker.getPosition());
      } else {
        var infoWindow = new AMap.InfoWindow({
          isCustom: true, //使用自定义窗体
          showShadow: true,
          content: content,
          offset: new AMap.Pixel(-10, 60)
        });
        infoWindow.open(map, marker.getPosition());
      }
    },
    initStoreMapIos(item) {
      var map = new AMap.Map('container', {
        resizeEnable: true,
        center: [Number(item.longitude), Number(item.latitude)],
        zoom: 19,
        viewMode: '3D' //使用3D视图
      });
      var marker = new AMap.Marker({
        position: [Number(item.longitude), Number(item.latitude)], //位置
        title: item.name,
        icon: new AMap.Icon({
          image: require('../assets/img/position.png'),
          size: new AMap.Size(170, 170), //图标所处区域大小
          imageSize: new AMap.Size(170, 170) //图标大小
        }),
        offset: new AMap.Pixel(-100, -200)
      })
      map.add(marker); //添加到地图
      let content =
          '<div class="map-info-window-content row-me center-all">' +
          '<div class="map-company-name">' + item.name + '</div>' +
          '</div>';
      if (this.isMobile) {
        var infoWindow = new AMap.InfoWindow({
          isCustom: true, //使用自定义窗体
          showShadow: true,
          content: content,
          offset: new AMap.Pixel(-10, 350)
        });
        infoWindow.open(map, marker.getPosition());
      } else {
        var infoWindow = new AMap.InfoWindow({
          isCustom: true, //使用自定义窗体
          showShadow: true,
          content: content,
          offset: new AMap.Pixel(-10, 60)
        });
        infoWindow.open(map, marker.getPosition());
      }
    },
    //是否苹果手机
    isIos() {
      let ua = navigator.userAgent.toLowerCase()
      return ua.indexOf('iphone') !== -1
    },
    //获取新闻列表
    getNews() {
      this.$postRequest('/api/index/getNews', {
        page: this.pageNews,
        limit: this.limitNews
      }).then((res) => {
        if (res) {
          this.newsTotal = res.data.total
          this.newsArray = res.data.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {
    $route: {
      handler(to, from) {
        if (to.name.indexOf('about_us') !== -1) {
          if (this.$route.query.index) {
            this.tabIndex = Number(this.$route.query.index)
          }
        }
      },
      deep: true
    },
    tabIndex(newVal, oldVal) {
      if (this.tabIndex === 1) {
        this.getHonor()
      } else if (this.tabIndex === 2) {
        this.getShops()
        this.getVideoList()
        this.getPhotos()
      } else if (this.tabIndex === 3) {
        this.getNews()
      }
    }
  },
  activated() {
    if (this.$route.query.index) {
      this.tabIndex = Number(this.$route.query.index)
    }
  }
}
</script>

<style lang="scss" scoped>
.about-us-mobile {
  .img-banner {
    width: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  .main-content {
    padding: 20px;

    .tab-div {
      background: #F4F4F4;
      border-radius: 10px;

      .left-div {
        width: 130px;
        background: #000000;
        border-radius: 10px 0px 0px 10px;
        padding: 10px 0;

        .title1 {
          font-size: 23px;
          color: #FFFFFF;
        }

        .title2 {
          font-size: 15px;
          color: #FFF10C;
          margin-top: 10px;
        }
      }

      .title0 {
        font-size: 20px;
        color: #999999;
        text-align: center;
        padding: 0 10px;
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }

        &-active {
          color: #666666;
          font-weight: bold;
        }
      }

      .tab-line {
        width: 3px;
        height: 20px;
        background: #999999;
        border-radius: 10px;
      }
    }

    .img-line {
      width: 80px;
    }

    .title {
      font-size: 28px;
      color: #000000;
      letter-spacing: 2px;
      margin-bottom: 10px;
    }

    .subtitle {
      font-size: 18px;
      color: #666666;
    }

    .img-develop {
      width: 100%;
      height: 250px;
      margin-top: 20px;
      object-fit: cover;
    }

    .card-div {
      width: 100%;
      height: 400px;
      border-radius: 20px;
      overflow: hidden;
      cursor: pointer;

      .yellow-mask {
        background: RGBA(251, 225, 12, 0.9);

        .title1 {
          font-size: 35px;
          font-weight: bold;
          color: #000000;
        }

        .title2 {
          font-size: 20px;
          color: #000000;
          margin-top: 20px;
        }
      }

      .show-div {
        .img2 {
          width: 100%;
          height: 220px;
          object-fit: cover;
        }

        .black1 {
          background-color: #000000;

          .title11 {
            font-size: 25px;
            color: #FFFFFF;
            margin-left: 25px;
            margin-top: 20px;
          }

          .title22 {
            font-size: 20px;
            color: #FFFFFF;
            margin-left: 25px;
            margin-right: 20px;
            margin-top: 20px;
            font-weight: 300;
          }
        }
      }
    }

    .black-div {
      background: #000000;
      overflow: auto;
      z-index: 100;

      .title-black {
        margin: 10px;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 30px;
        font-weight: 300;
      }
    }

    .item-card {
      box-shadow: 0px 10px 30px 0px rgba(40, 40, 40, 0.3);
      cursor: pointer;
      margin-bottom: 30px;
      padding-bottom: 20px;

      &:hover {
        background-color: #FADA0F;
      }

      .img-card {
        width: 100%;
        height: auto;
        object-fit: fill;
      }

      .title1 {
        font-size: 25px;
        color: #222222;
        font-weight: bold;
        margin: 20px 20px 0 20px;
      }

      .title2 {
        font-size: 20px;
        color: #222222;
        margin: 10px 20px 0 20px;
      }

      .title3 {
        font-size: 20px;
        color: #222222;
        margin: 10px 20px 20px 20px;
      }
    }
  }

  .item-show {
    width: 100%;
    padding: 10px 0;
    background: #F6F6F6;
    margin-bottom: 20px;
    cursor: pointer;

    &:hover {
      background: #FFE100;

      .look-div {
        background-color: #000000;
        color: #FFFFFF;
      }
    }

    &:nth-child(2n) {
      margin-right: 0;
    }

    .item-img {
      width: 60px;
      height: 60px;
      margin-left: 10px;
    }

    .title1 {
      font-size: 22px;
      color: #000000;
      font-weight: bold;
    }

    .title2 {
      font-size: 18px;
      color: #000000;
      margin-top: 10px;
    }

    .look-div {
      width: 60px;
      height: 30px;
      background: #FFE100;
      line-height: 30px;
      text-align: center;
      font-size: 18px;
      color: #000000;
      margin-right: 10px;
    }
  }

  .item-video {
    width: 100%;
    height: 250px;
    margin-bottom: 15px;

    &:nth-child(2n) {
      margin-right: 0;
    }

    .video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }

  .img-more {
    width: 140px;
    height: 35px;
    margin-top: 50px;
    cursor: pointer;
  }

  .img-shop {
    width: 100%;
    background-color: lightblue;
    object-fit: fill;
  }

  .item-news {
    width: 100%;
    height: 120px;
    background: #FFFFFF;
    box-shadow: 0px 10px 30px 0px rgba(40, 40, 40, 0.1);
    cursor: pointer;
    margin-bottom: 20px;

    &:hover {
      background: #FADA0F;
    }

    .img-news {
      width: 150px;
      height: 100%;
      object-fit: cover;
    }

    .news-title {
      font-size: 15px;
      font-weight: bold;
      color: #222222;
      margin-top: 10px;
    }

    .news-date {
      font-size: 13px;
      color: #222222;
    }

    .news-content {
      font-size: 15px;
      color: #222222;
    }
  }

  .map-div {
    width: 100%;
    height: 50vh;
  }

  .round-date-div {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 5px solid #000000;

    .date {
      font-size: 20px;
      color: #333333;
      font-weight: bold;
    }

    .year {
      font-size: 12px;
      color: #999999;
    }
  }

  .right-content {
    border-left: 2px solid #ECECEC;
    margin-left: 35px;

    .black-point {
      width: 12px;
      height: 12px;
      background: #000000;
      border-radius: 50%;
      margin-left: -7px;
    }

    .grey-point {
      width: 10px;
      height: 10px;
      background: #D0D0D0;
      border-radius: 50%;
      opacity: 1;
      margin-left: 30px;
    }

    .month {
      font-size: 20px;
      color: #666666;
      margin-left: 10px;
    }

    .content {
      font-size: 18px;
      color: #333333;
      margin-left: 10px;
    }
  }
}

.about-us {
  .img-banner {
    width: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  .main-content {
    padding: 80px 100px;

    .tab-div {
      height: 100px;
      background: #F4F4F4;
      border-radius: 10px;

      .left-div {
        width: 330px;
        height: 100px;
        background: #000000;
        border-radius: 10px 0px 0px 10px;

        .title1 {
          font-size: 30px;
          color: #FFFFFF;
        }

        .title2 {
          font-size: 18px;
          color: #FFF10C;
          margin-bottom: -15px;
        }
      }

      .title0 {
        font-size: 26px;
        color: #999999;
        text-align: center;
        padding: 0 30px;
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }

        &-active {
          color: #666666;
          font-weight: bold;
        }
      }

      .tab-line {
        width: 3px;
        height: 30px;
        background: #999999;
        border-radius: 10px;
      }
    }

    .img-line {
      width: 145px;
      object-fit: scale-down;
      margin-bottom: 10px;
    }

    .title {
      font-size: 48px;
      color: #000000;
      letter-spacing: 5px;
    }

    .subtitle {
      font-size: 24px;
      color: #666666;
    }

    .img-develop {
      width: 1000px;
      height: 590px;
      object-fit: cover;
    }

    .card-div {
      height: 650px;
      border-radius: 20px;
      overflow: hidden;
      cursor: pointer;

      .yellow-mask {
        background: RGBA(251, 225, 12, 0.9);

        .title1 {
          font-size: 50px;
          color: #000000;
        }

        .title2 {
          font-size: 25px;
          color: #000000;
          margin-top: 20px;
        }
      }

      .show-div {
        .img2 {
          object-fit: cover;
          position: absolute;
          left: 0;
          width: 100%;
          top: 0;
          height: 100%;
        }

        .black1 {
          background-color: #000000;
          position: absolute;
          left: 0;
          width: 100%;
          bottom: 0;
          height: 214px;

          .title11 {
            font-size: 46px;
            color: #FFFFFF;
            margin-left: 60px;
            margin-top: 20px;
          }

          .title22 {
            font-size: 26px;
            color: #FFFFFF;
            line-height: 40px;
            margin-left: 60px;
            margin-top: 20px;
            font-weight: 300;
          }
        }
      }
    }

    .black-div {
      background: #000000;
      overflow: auto;
      margin-right: -250px;
      z-index: 100;

      .title-black {
        margin: 40px;
        font-size: 24px;
        color: #FFFFFF;
        line-height: 44px;
        font-weight: 300;
      }
    }

    .item-card {
      box-shadow: 0px 10px 30px 0px rgba(40, 40, 40, 0.3);
      cursor: pointer;
      padding-bottom: 20px;

      &:hover {
        background-color: #FADA0F;
      }

      .img-card {
        width: 100%;
        height: auto;
        object-fit: fill;
      }

      .title1 {
        font-size: 30px;
        color: #222222;
        font-weight: bold;
        margin: 40px 30px 20px 30px;
      }

      .title2 {
        font-size: 20px;
        color: #222222;
        margin: 0 30px;
      }

      .title3 {
        font-size: 20px;
        color: #222222;
        line-height: 30px;
        margin: 30px 30px 40px 30px;
      }
    }
  }

  .item-show {
    width: 844px;
    height: 150px;
    background: #F6F6F6;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;

    &:hover {
      background: #FFE100;

      .look-div {
        background-color: #000000;
        color: #FFFFFF;
      }
    }

    &:nth-child(2n) {
      margin-right: 0;
    }

    .title1 {
      font-size: 30px;
      color: #000000;
      font-weight: bold;
    }

    .title2 {
      font-size: 24px;
      color: #000000;
      margin-top: 10px;
    }

    .look-div {
      width: 90px;
      height: 36px;
      background: #FFE100;
      line-height: 36px;
      text-align: center;
      font-size: 24px;
      color: #000000;
      margin-top: 33px;
      margin-right: 33px;
    }
  }

  .item-video {
    width: 700px;
    height: 400px;
    margin-right: 15px;
    margin-bottom: 15px;

    &:nth-child(2n) {
      margin-right: 0;
    }

    .video {
      width: 100%;
      height: 100%;
    }
  }

  .img-more {
    width: 320px;
    height: 60px;
    object-fit: scale-down;
    margin-top: 80px;
    cursor: pointer;
  }

  .img-shop {
    width: 100%;
    background-color: lightblue;
    object-fit: fill;
  }

  .item-news {
    width: 100%;
    height: 250px;
    background: #FFFFFF;
    box-shadow: 0px 10px 30px 0px rgba(40, 40, 40, 0.1);
    cursor: pointer;
    margin-bottom: 40px;

    &:hover {
      background: #FADA0F;
    }

    .img-news {
      width: 396px;
      height: 100%;
      object-fit: cover;
    }

    .news-title {
      font-size: 30px;
      font-weight: bold;
      color: #222222;
    }

    .news-date {
      font-size: 24px;
      color: #222222;
    }

    .news-content {
      font-size: 20px;
      color: #222222;
      line-height: 36px;
    }
  }

  .map-div {
    width: 100%;
    height: 60vh;
  }

  .round-date-div {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 10px solid #000000;

    .date {
      font-size: 28px;
      color: #333333;
      font-weight: bold;
    }

    .year {
      font-size: 14px;
      color: #999999;
    }
  }

  .right-content {
    border-left: 2px solid #ECECEC;
    margin-left: 55px;

    .black-point {
      width: 12px;
      height: 12px;
      background: #000000;
      border-radius: 50%;
      margin-left: -7px;
    }

    .grey-point {
      width: 10px;
      height: 10px;
      background: #D0D0D0;
      border-radius: 50%;
      opacity: 1;
      margin-left: 110px;
    }

    .month {
      font-size: 20px;
      color: #666666;
      margin-left: 20px;
    }

    .content {
      font-size: 24px;
      color: #333333;
      margin-left: 20px;
    }
  }
}
</style>
